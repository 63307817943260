import BandwagonDotCom from "../../components/BandwagonDotCom"
import React from "react"
import TeamMember from "../../components/TeamMember"

import { StaticImage } from "gatsby-plugin-image"

const HamptonSmith = () => <BandwagonDotCom>
  <TeamMember
        headshot=<StaticImage src="../../images/hampton-smith-headshot.jpg"
                alt="Hampton Smith" />
        name="Hampton Smith"
        position="Chief Technology Officer"
  >
      <p>
        The brains behind our proprietary Blockchain technology, the core of our
        solution service, Hampton is an ex-Googler and early Bitcoin and
        Cryptocurrency enthusiast. From his prior experience as an indie video
        game and software developer and Ph.D. in Computer Science from Clemson
        University, Hampton’s tech-savvy guided the decision to build our
        technology on the IBM Hyperledger Fabric Blockchain. Hampton knows his
        stuff…and that helps our clients to really know their fans.
      </p>
      <h3>Fun Fact</h3>
      <hr/>
      <p>
        He wrote a musical in high school with a friend and it debuted at a
        local theater in his home town in Virginia.
      </p>
      <h3>Why I love Bandwagon</h3>
      <hr/>
      <p>
        New challenges every day!
      </p>
  </TeamMember>
</BandwagonDotCom>;

export default HamptonSmith
